var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{tag:"component"},[_c('div',{staticClass:"physician-form"},[_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();_vm.alertTabError();handleSubmit(_vm.save)}}},[_c('b-tabs',{staticClass:"g-med-tabs",attrs:{"pills":""}},[_c('b-tab',{staticClass:"g-med-tab",attrs:{"active":""},on:{"click":function($event){_vm.tabPersonError = false}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',[_c('feather-icon',{staticClass:"mr-0 mr-sm-50",attrs:{"icon":"UserIcon","size":"16"}}),_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v("Pessoal")]),_c('feather-icon',{staticClass:"error-icon text-danger",class:{'error-icon-show': _vm.tabPersonError},attrs:{"icon":"AlertCircleIcon","size":"16"}})],1)]},proxy:true}],null,true)},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('b-form-group',{attrs:{"id":"cpf-input","label":"CPF","label-for":"cpf"}},[_c('validation-provider',{attrs:{"name":"CPF","vid":"cpf","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"label":"CPF","label-for":"cpf"}},[_c('cleave',{staticClass:"form-control",attrs:{"disabled":_vm.loading,"raw":false,"options":_vm.options.customDelimiter},model:{value:(_vm.physician.cpf),callback:function ($$v) {_vm.$set(_vm.physician, "cpf", $$v)},expression:"physician.cpf"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"disabled":_vm.loading,"variant":"outline-primary"},on:{"click":_vm.debounceSearchByCpf}},[(!_vm.loading)?_c('feather-icon',{attrs:{"icon":"SearchIcon","size":"14"}}):_vm._e(),(_vm.loading)?_c('feather-icon',{staticClass:"spin",attrs:{"icon":"LoaderIcon","size":"14"}}):_vm._e()],1)],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Nome completo","label-for":"nome"}},[_c('validation-provider',{attrs:{"name":"Nome","vid":"nome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"nome","disabled":!_vm.formEnabled},model:{value:(_vm.physician.name),callback:function ($$v) {_vm.$set(_vm.physician, "name", $$v)},expression:"physician.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('b-form-group',{attrs:{"label":"Nascimento","label-for":"birthday"}},[_c('validation-provider',{attrs:{"name":"Nascimento","vid":"birthday","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"birthday-datepicker","config":_vm.dateConfig,"disabled":!_vm.formEnabled},on:{"on-change":_vm.changeBirthday},model:{value:(_vm.birthday),callback:function ($$v) {_vm.birthday=$$v},expression:"birthday"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"E-mail","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"email","vid":"email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","disabled":!_vm.formEnabled},model:{value:(_vm.physician.email),callback:function ($$v) {_vm.$set(_vm.physician, "email", $$v)},expression:"physician.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Telefone","label-for":"telefone"}},[_c('validation-provider',{attrs:{"name":"Telefone","vid":"telefone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"id":"telefone","raw":false,"disabled":!_vm.formEnabled,"options":_vm.physician.phone.length > 14 ? _vm.options.phoneWith9 : _vm.options.phone},model:{value:(_vm.physician.phone),callback:function ($$v) {_vm.$set(_vm.physician, "phone", $$v)},expression:"physician.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"CRM","label-for":"crm"}},[_c('validation-provider',{attrs:{"name":"CRM","vid":"crm","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"crm","disabled":!_vm.formEnabled},model:{value:(_vm.physician.crm),callback:function ($$v) {_vm.$set(_vm.physician, "crm", $$v)},expression:"physician.crm"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Estado do CRM","label-for":"state_id"}},[_c('validation-provider',{attrs:{"name":"Estado do CRM","vid":"state_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"state_id","disabled":!_vm.formEnabled,"options":_vm.crmStateOptions},model:{value:(_vm.physician.state_id),callback:function ($$v) {_vm.$set(_vm.physician, "state_id", $$v)},expression:"physician.state_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"d-flex align-items-center",attrs:{"cols":"6","lg":"4"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('div',[_c('label',{attrs:{"for":"textarea-default"}},[_c('b-form-checkbox',{staticClass:"custom-control-danger",attrs:{"name":"schedule-restriction","switch":"","inline":"","disabled":!_vm.formEnabled},on:{"change":_vm.changeRestriction},model:{value:(_vm.physician.schedule_restriction),callback:function ($$v) {_vm.$set(_vm.physician, "schedule_restriction", $$v)},expression:"physician.schedule_restriction"}},[_vm._v(" Com restrição? ")])],1),_c('b-form-textarea',{attrs:{"id":"textarea-default","disabled":!_vm.physician.schedule_restriction,"placeholder":"Descrição da restrição","rows":"3"},model:{value:(_vm.physician.schedule_restriction_reason),callback:function ($$v) {_vm.$set(_vm.physician, "schedule_restriction_reason", $$v)},expression:"physician.schedule_restriction_reason"}})],1)]),_c('b-col',[_c('b-col',{attrs:{"cols":"12"}},[_c('h4',{staticClass:"d-inline-block pr-2"},[_vm._v(" CNPJ Vinculado ")]),_c('b-button',{attrs:{"title":"Adicionar CNPJ","variant":"primary","size":"sm"},on:{"click":function($event){return _vm.addCompany()}}},[_c('feather-icon',{attrs:{"icon":"PlusIcon","size":"16"}})],1)],1),_c('b-col',_vm._l((_vm.physician.companies),function(company,index){return _c('b-row',{key:("company-" + index)},[_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"CNPJ","label-for":("cnpj-" + index)}},[_c('validation-provider',{attrs:{"name":"cnpj","vid":("cnpj-" + index),"rules":"cnpj|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"id":("cnpj-" + index),"raw":false,"options":_vm.options.cnpjDelimiter,"disabled":!company.can_update},model:{value:(company.cnpj),callback:function ($$v) {_vm.$set(company, "cnpj", $$v)},expression:"company.cnpj"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(company.cnpj && _vm.validCompanies[company.cnpj] && _vm.validCompanies[company.cnpj].length > 1)?_c('small',{staticClass:"text-danger"},[_vm._v("CNPJ "+_vm._s(company.cnpj)+" duplicado")]):_vm._e()]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Razão Social","label-for":("name-" + index)}},[_c('validation-provider',{attrs:{"name":"name","vid":("name-" + index),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":("name-" + index),"disabled":!company.can_update},model:{value:(company.name),callback:function ($$v) {_vm.$set(company, "name", $$v)},expression:"company.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"2"}},[_c('label',{attrs:{"for":"status-id"}},[_vm._v("Status")]),_c('v-select',{attrs:{"id":"status-id","name":"status-id","options":_vm.companyStatusOptions,"item-value":"value","item-text":"label","label":"label","reduce":function (value) { return value.value; },"input-id":"value","placeholder":"Status","clearable":false},model:{value:(company.active),callback:function ($$v) {_vm.$set(company, "active", $$v)},expression:"company.active"}})],1),_c('b-col',{staticClass:"d-flex align-items-center",attrs:{"cols":"2"}},[_c('b-button',{staticClass:"btn-remove",attrs:{"title":"Remover","variant":"danger","size":"sm"},on:{"click":function($event){return _vm.removeCompany(index)}}},[_c('feather-icon',{attrs:{"icon":"MinusIcon","size":"16"}})],1)],1)],1)}),1)],1)],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mt-1 mr-1",attrs:{"variant":"primary","type":"submit"}},[_vm._v(" Salvar ")])],1)],1)],1)]}}])})],1),_c('b-modal',{ref:"physicianModal",attrs:{"id":"modal-physician","title":"Médico encontrado","hide-footer":"","centered":""}},[_c('div',[_c('div',{staticClass:"mb-1"},[_vm._v(" Médico existente em sua instituição: ")]),_c('div',[_vm._v("Nome: "),_c('strong',[_vm._v(_vm._s(_vm.foundPhysician.name))])]),_c('div',[_vm._v("CPF: "),_c('strong',[_vm._v(_vm._s(_vm.foundPhysician.cpf))])]),_c('div',{staticClass:"text-right"},[_c('b-button',{staticClass:"ml-1",attrs:{"variant":"primary","to":{ name: 'physician-view', params: { id: _vm.foundPhysician.id } }}},[_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v("Acessar cadastro")])])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }