<template>
  <component :is="'b-card'">
    <div class="physician-form">
      <validation-observer ref="refFormObserver" #default="{ handleSubmit }">
        <b-form
          @submit.prevent="alertTabError();handleSubmit(save)"
        >
          <b-tabs
            pills
            class="g-med-tabs"
          >
            <b-tab class="g-med-tab" active @click="tabPersonError = false">
              <template #title>
                <div>
                  <feather-icon
                    icon="UserIcon"
                    size="16"
                    class="mr-0 mr-sm-50"
                  />
                  <span class="d-none d-sm-inline">Pessoal</span>
                  <feather-icon
                    :class="{'error-icon-show': tabPersonError}"
                    icon="AlertCircleIcon"
                    size="16"
                    class="error-icon text-danger"
                  />
                </div>
              </template>

              <b-row>
                <b-col cols="12" md="3">
                  <b-form-group id="cpf-input" label="CPF" label-for="cpf">
                    <validation-provider
                      #default="{ errors }"
                      name="CPF"
                      vid="cpf"
                      rules="required"
                    >
                      <b-input-group
                        label="CPF"
                        label-for="cpf"
                      >
                        <cleave
                          v-model="physician.cpf"
                          class="form-control"
                          :disabled="loading"
                          :raw="false"
                          :options="options.customDelimiter"
                        />
                        <b-input-group-append>
                          <b-button :disabled="loading" variant="outline-primary" @click="debounceSearchByCpf">
                            <feather-icon
                              v-if="!loading"
                              icon="SearchIcon"
                              size="14"
                            />
                            <feather-icon
                              v-if="loading"
                              class="spin"
                              icon="LoaderIcon"
                              size="14"
                            />
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group label="Nome completo" label-for="nome">
                    <validation-provider
                      #default="{ errors }"
                      name="Nome"
                      vid="nome"
                      rules="required"
                    >
                      <b-form-input id="nome" v-model="physician.name" :disabled="!formEnabled" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="3">
                  <b-form-group label="Nascimento" label-for="birthday">
                    <validation-provider
                      #default="{ errors }"
                      name="Nascimento"
                      vid="birthday"
                      rules="required"
                    >
                      <b-form-group>
                        <flat-pickr
                          id="birthday-datepicker"
                          v-model="birthday"
                          class="form-control"
                          :config="dateConfig"
                          :disabled="!formEnabled"
                          @on-change="changeBirthday"
                        />
                      </b-form-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group label="E-mail" label-for="email">
                    <validation-provider
                      #default="{ errors }"
                      name="email"
                      vid="email"
                      rules="required"
                    >
                      <b-form-input id="email" v-model="physician.email" :disabled="!formEnabled" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group label="Telefone" label-for="telefone">
                    <validation-provider
                      #default="{ errors }"
                      name="Telefone"
                      vid="telefone"
                      rules="required"
                    >
                      <cleave
                        id="telefone"
                        v-model="physician.phone"
                        class="form-control"
                        :raw="false"
                        :disabled="!formEnabled"
                        :options="physician.phone.length > 14 ? options.phoneWith9 : options.phone"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col cols="12" md="6">
                  <b-row>
                    <b-col cols="12" md="6">
                      <b-form-group label="CRM" label-for="crm">
                        <validation-provider
                          #default="{ errors }"
                          name="CRM"
                          vid="crm"
                          rules="required"
                        >
                          <b-form-input id="crm" v-model="physician.crm" :disabled="!formEnabled" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6">
                      <b-form-group label="Estado do CRM" label-for="state_id">
                        <validation-provider
                          #default="{ errors }"
                          name="Estado do CRM"
                          vid="state_id"
                          rules="required"
                        >
                          <b-form-select
                            id="state_id"
                            v-model="physician.state_id"
                            :disabled="!formEnabled"
                            :options="crmStateOptions"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="6" lg="4" class="d-flex align-items-center" />
                  </b-row>
                </b-col>
                <b-col cols="12" md="6">
                  <div>
                    <label for="textarea-default">
                      <b-form-checkbox
                        v-model="physician.schedule_restriction"
                        name="schedule-restriction"
                        class="custom-control-danger"
                        switch
                        inline
                        :disabled="!formEnabled"
                        @change="changeRestriction"
                      >
                        Com restrição?
                      </b-form-checkbox>
                    </label>
                    <b-form-textarea
                      id="textarea-default"
                      v-model="physician.schedule_restriction_reason"
                      :disabled="!physician.schedule_restriction"
                      placeholder="Descrição da restrição"
                      rows="3"
                    />
                  </div>
                </b-col>
                <b-col>
                  <b-col cols="12">
                    <h4 class="d-inline-block pr-2">
                      CNPJ Vinculado
                    </h4>
                    <b-button
                      title="Adicionar CNPJ"
                      variant="primary"
                      size="sm"
                      @click="addCompany()"
                    >
                      <feather-icon
                        icon="PlusIcon"
                        size="16"
                      />
                    </b-button>
                  </b-col>
                  <b-col>
                    <b-row v-for="(company, index) in physician.companies" :key="`company-${index}`">
                      <b-col cols="4">
                        <b-form-group label="CNPJ" :label-for="`cnpj-${index}`">
                          <validation-provider
                            #default="{ errors }"
                            name="cnpj"
                            :vid="`cnpj-${index}`"
                            rules="cnpj|required"
                          >
                            <cleave
                              :id="`cnpj-${index}`"
                              v-model="company.cnpj"
                              class="form-control"
                              :raw="false"
                              :options="options.cnpjDelimiter"
                              :disabled="!company.can_update"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                            <small v-if="company.cnpj && validCompanies[company.cnpj] && validCompanies[company.cnpj].length > 1" class="text-danger">CNPJ {{ company.cnpj }} duplicado</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="4">
                        <b-form-group label="Razão Social" :label-for="`name-${index}`">
                          <validation-provider
                            #default="{ errors }"
                            name="name"
                            :vid="`name-${index}`"
                            rules="required"
                          >
                            <b-form-input :id="`name-${index}`" v-model="company.name" :disabled="!company.can_update" />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="2">
                        <label for="status-id">Status</label>
                        <v-select
                          id="status-id"
                          v-model="company.active"
                          name="status-id"
                          :options="companyStatusOptions"
                          item-value="value"
                          item-text="label"
                          label="label"
                          :reduce="value => value.value"
                          input-id="value"
                          placeholder="Status"
                          :clearable="false"
                        />
                      </b-col>
                      <b-col cols="2" class="d-flex align-items-center">
                        <b-button
                          class="btn-remove"
                          title="Remover"
                          variant="danger"
                          size="sm"
                          @click="removeCompany(index)"
                        >
                          <feather-icon
                            icon="MinusIcon"
                            size="16"
                          />
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>
          <b-row>
            <b-col cols="12">
              <b-button
                variant="primary"
                class="mt-1 mr-1"
                type="submit"
              >
                Salvar
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </div>

    <b-modal
      id="modal-physician"
      ref="physicianModal"
      title="Médico encontrado"
      hide-footer
      centered
    >
      <div>
        <div class="mb-1">
          Médico existente em sua instituição:
        </div>
        <div>Nome: <strong>{{ foundPhysician.name }}</strong></div>
        <div>CPF: <strong>{{ foundPhysician.cpf }}</strong></div>
        <div class="text-right">
          <b-button
            variant="primary"
            class="ml-1"
            :to="{ name: 'physician-view', params: { id: foundPhysician.id } }"
          >
            <span class="d-none d-sm-inline">Acessar cadastro</span>
          </b-button>
        </div>
      </div>
    </b-modal>
  </component>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import formValidation from '@core/comp-functions/forms/form-validation'
import {
  BModal,
  BTab,
  BTabs,
  BCard,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BFormSelect,
  BButton,
  BFormTextarea,
  BFormCheckbox,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
import store from '@/store'
import {
  computed, onUnmounted, ref, watch,
} from '@vue/composition-api'
import { required } from '@validations'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import formError from '@core/comp-functions/forms/form-error'
import formValidation from '@core/comp-functions/forms/form-validation'
import { sortBy, debounce } from 'lodash'
import Fuse from 'fuse.js'
import { Portuguese } from 'flatpickr/dist/l10n/pt'
import { format } from 'date-fns'
import { onlyNumbers } from '@core/utils/utils'
import Vue from 'vue'
import vSelect from 'vue-select'
import groupBy from 'lodash/groupBy'
import physicianStoreModule from '../physicianStoreModule'

export default {
  components: {
    vSelect,
    BModal,
    BInputGroupAppend,
    flatPickr,
    BTab,
    BTabs,
    BCard,
    BForm,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BFormSelect,
    BButton,
    BFormTextarea,
    BFormCheckbox,
    Cleave,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      dateConfig: {
        dateFormat: 'd/m/Y',
        locale: Portuguese,
      },
      options: {
        cnpjDelimiter: {
          delimiters: ['.', '.', '/', '-'],
          blocks: [2, 3, 3, 4, 2],
          numericOnly: true,
        },
        customDelimiter: {
          delimiters: ['.', '.', '-'],
          blocks: [3, 3, 3, 2],
          numericOnly: true,
        },
        phone: {
          delimiters: ['(', ')', ' ', '-'],
          blocks: [0, 2, 0, 4, 5],
          numericOnly: true,
          delimiterLazyShow: true,
        },
        phoneWith9: {
          delimiters: ['(', ')', ' ', '-'],
          blocks: [0, 2, 0, 5, 4],
          numericOnly: true,
          delimiterLazyShow: true,
        },
        postalCode: {
          delimiters: ['-'],
          blocks: [5, 3],
          numericOnly: true,
        },
        income: {
          numeralPositiveOnly: true,
          numericOnly: true,
          numeral: true,
          numeralDecimalMark: ',',
          delimiter: '.',
          numeralDecimalScale: 2,
          prefix: 'R$ ',
          rawValueTrimPrefix: true,
        },
      },
    }
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-physician'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) { store.registerModule(USER_APP_STORE_MODULE_NAME, physicianStoreModule) }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) { store.unregisterModule(USER_APP_STORE_MODULE_NAME) }
    })

    const toast = useToast()

    const {
      refFormObserver,
      getValidationState,
    } = formValidation(() => {})
    const physicianModal = ref(null)
    const physician = ref({
      cpf: '',
      phone: '',
      schedule_restriction: false,
      schedule_restriction_reason: null,
      state_id: null,
      account: {},
      companies: [],
    })
    const birthday = ref(null)
    const tabPersonError = ref(false)
    const tabBankError = ref(false)
    const tabAddressError = ref(false)
    const postalCode = ref('')
    const foundPhysician = ref({})
    const stateId = ref(null)
    const cityId = ref(null)
    const cityName = ref('') // campo apenas para guardar nome da cidade que vem do consumo da api do viacep
    const street = ref('')
    const number = ref('')
    const complement = ref('')
    const neighborhood = ref('')
    const financialInstitutionId = ref('')
    const loading = ref(false)
    const formEnabled = ref(false)

    const states = ref([])
    const stateOptions = ref([])
    const crmStateOptions = ref([])
    const cities = ref([])
    const cityOptions = ref([])
    const bankOptions = ref([
      { label: 'Informe banco', value: null },
    ])
    const accountTypes = ref([
      {
        value: 'checking_account',
        text: 'Corrente',
      },
      {
        value: 'savings_account',
        text: 'Poupança',
      },
    ])
    const companyStatusOptions = [
      { label: 'Ativo', value: true },
      { label: 'Inativo', value: false },
    ]

    const tabsMap = {
      // person
      cpf: tabPersonError,
      bank: tabBankError,
      nome: tabPersonError,
      email: tabPersonError,
      telefone: tabPersonError,
      crm: tabPersonError,
      state_id: tabPersonError,
      birthday: tabPersonError,
      // bank
      'account.financial_institution_id': tabBankError,
      accountType: tabBankError,
      branch_number: tabBankError,
      accountNumber: tabBankError,
      account_code: tabBankError,
      branch_code: tabBankError,
      // address
      cep: tabAddressError,
      estado: tabAddressError,
      bairro: tabAddressError,
      rua: tabAddressError,
    }

    async function alertTabError() {
      tabPersonError.value = false
      tabBankError.value = false
      tabAddressError.value = false
      await refFormObserver.value.validate()
      const { errors } = refFormObserver.value
      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const field in errors) {
        if (errors[field].length > 0 && tabsMap[field]) {
          tabsMap[field].value = true
        }
      }
    }

    const accountType = ref(accountTypes.value[0])

    const fetchCities = ({ stateId: state }) => store.dispatch(`${USER_APP_STORE_MODULE_NAME}/fetchCities`, {
      stateId: state,
    }).then(resp => {
      cities.value = resp.data
      cityOptions.value = resp.data.map(city => ({
        value: city.id,
        text: city.name,
      }))
    })

    function addCompany() {
      physician.value.companies.push({
        cnpj: '', name: '', can_update: true, active: true,
      })
    }

    const getAddressByZipCode = zipCode => store.dispatch(`${USER_APP_STORE_MODULE_NAME}/getAddressByZipCode`, { zipCode })
    const getState = stateCode => states.value.find(s => s.abbreviation === stateCode)

    function searchByCpf() {
      const cpf = onlyNumbers(physician.value.cpf)
      if (cpf.length < 11) { return }

      loading.value = true
      store.dispatch(`${USER_APP_STORE_MODULE_NAME}/searchPhysicianByCpf`, { cpf })
        .then(resp => {
          loading.value = false
          foundPhysician.value = resp.data.data
          if (foundPhysician.value && foundPhysician.value.exist_in_institution) {
            physicianModal.value.show()
          } else if (foundPhysician.value) {
            formEnabled.value = true
            physician.value.name = foundPhysician.value.name
            physician.value.birthday = foundPhysician.value.birthday
            physician.value.crm = foundPhysician.value.crm
            physician.value.state_id = foundPhysician.value.state_id
            birthday.value = foundPhysician.value.birthday
          }
        })
        .catch(() => {
          formEnabled.value = true
          loading.value = false
        })
    }

    const debounceSearchByCpf = debounce(() => {
      searchByCpf()
    }, 1500)

    watch(() => physician.value.cpf, () => {
      debounceSearchByCpf()
    })

    watch(postalCode, current => {
      if (current.length === 9) {
        getAddressByZipCode(current)
          .then(async data => {
            const state = getState(data.state)
            await fetchCities({ stateId: state.id })
            const city = cities.value.find(c => c.name === data.city)
            street.value = data.street
            neighborhood.value = data.neighborhood
            complement.value = data.complement
            cityId.value = city.id
            stateId.value = state.id
          })
      }
    })

    const fetchStates = () => {
      store.dispatch(`${USER_APP_STORE_MODULE_NAME}/fetchStates`, {})
        .then(resp => {
          states.value = resp.data
          stateOptions.value = resp.data.map(state => ({
            value: state.id,
            text: `${state.name} (${state.abbreviation})`,
          }))
          crmStateOptions.value = resp.data.map(state => ({
            value: state.id,
            text: state.abbreviation,
          }))
          physician.value = {
            ...physician.value,
            state_id: null,
          }
        })
        .catch(err => {
          // TODO: subir um toats indicando o erro
          // eslint-disable-next-line no-console
          console.log(err)
        })
    }
    fetchStates()

    const fetchFinancialInstitutions = () => {
      store.dispatch(`${USER_APP_STORE_MODULE_NAME}/fetchFinancialInstitutions`, { params: { perPage: 9999 } })
        .then(resp => {
          const banks = sortBy(resp.data, ['name'])
          bankOptions.value = banks.map(bank => ({
            value: bank.id,
            label: `${bank.name} - ${bank.bank_code}`,
          }))
        })
    }
    fetchFinancialInstitutions()

    function updateBankId() {
      if (financialInstitutionId.value) {
        physician.value.account.financial_institution_id = financialInstitutionId.value.value
      } else {
        physician.value.account.financial_institution_id = null
      }
    }

    const validCompanies = computed(() => {
      if (physician.value?.companies.length) {
        return groupBy(physician.value?.companies, 'cnpj')
      }
      return {}
    })

    function alertError(message) {
      Vue.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Erro',
          icon: 'XOctagonIcon',
          variant: 'danger',
          text: message,
        },
      })
    }

    function save() {
      physician.value.account.account_type = accountType.value.value

      const cnpjs = Object.keys(validCompanies.value)
      // eslint-disable-next-line no-restricted-syntax
      for (const cnpj of cnpjs) {
        if (validCompanies.value[cnpj].length > 1) {
          alertError('CNPJ duplicados')
          return
        }
      }

      const body = {
        ...physician.value,
        cpf: physician.value.cpf.replace(/\D/g, ''),
        phone: physician.value.phone.replace(/\D/g, ''),
        // address: {
        //   street: street.value,
        //   number: number.value,
        //   postal_code: postalCode.value.replace(/\D/g, ''),
        //   neighborhood: neighborhood.value,
        //   complement: complement.value,
        //   city_id: cityId.value,
        // },
      }

      if (body.birthday && !/^\d{4}-\d{2}-\d{2}$/.test(body.birthday)) {
        alertError('Nascimento formato inválido')
        return
      }

      store.dispatch(`${USER_APP_STORE_MODULE_NAME}/storePhysician`, body)
        .then(() => {
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Sucesso',
              icon: 'CheckIcon',
              variant: 'success',
              text: 'Médico cadastrado com sucesso',
            },
          })
          router.push('/physician/list')
        })
        .catch(error => {
          const { setErrors, getErrorMessage } = formError(this.$refs.refFormObserver, error)
          setErrors()
          if (getErrorMessage()) {
            alertError(getErrorMessage())
          }
        })
    }

    function filterFinancialInstitutions(options, search) {
      const fuse = new Fuse(options, {
        keys: ['label'],
        shouldSort: true,
      })
      return search.length
        ? fuse.search(search).map(({ item }) => item)
        : fuse.list
    }

    function changeRestriction() {
      if (!physician.value.schedule_restriction) {
        physician.value.schedule_restriction_reason = null
      }
    }

    function changeBirthday(birth) {
      if (birth[0]) {
        physician.value.birthday = format(birth[0], 'yyyy-MM-dd')
      } else {
        physician.value.birthday = null
      }
    }

    return {
      birthday,
      changeBirthday,
      stateOptions,
      crmStateOptions,
      states,
      cities,
      cityOptions,
      required,
      physician,
      loading,
      postalCode,
      stateId,
      fetchCities,
      cityId,
      cityName,
      street,
      number,
      complement,
      neighborhood,
      save,
      getValidationState,
      refFormObserver,
      bankOptions,
      financialInstitutionId,
      updateBankId,
      accountType,
      accountTypes,
      tabPersonError,
      tabBankError,
      tabAddressError,
      filterFinancialInstitutions,
      alertTabError,
      changeRestriction,
      debounce,
      physicianModal,
      foundPhysician,
      debounceSearchByCpf,
      formEnabled,
      addCompany,
      validCompanies,
      companyStatusOptions,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
.error-icon {
  margin-left: 5px;
  opacity: 0;
}
.error-icon-show {
  opacity: 1;
}
.btn-group {
  height: 38px;
}
fieldset {
  margin-bottom: 0!important;
}
@-webkit-keyframes rotation {
  from {-webkit-transform: rotate(0deg);}
  to   {-webkit-transform: rotate(359deg);}
}

.spin {
  -webkit-animation: rotation 3s infinite linear;
}
#cpf-input {

  input {
    border-right: 0;
  }
  button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
#birthday-datepicker[disabled] {
  background-color: #efefef;
}
</style>
