import axios from '@axios'
import cep from 'cep-promise'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    downloadReport(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/physician/index-export', { params: queryParams, responseType: 'blob' })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getAddressByZipCode(ctx, { zipCode }) {
      return new Promise((resolve, reject) => {
        const code = zipCode.replace(/\D/g, '')
        cep(code)
          .then(data => {
            resolve({
              street: data.street,
              neighborhood: data.neighborhood,
              complement: '',
              city: data.city,
              state: data.state,
            })
          })
          .catch(reject)
      })
    },
    fetchPhysicians(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/physician', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPhysician(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/physician/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updatePhysician(ctx, physicianData) {
      return new Promise((resolve, reject) => {
        const {
          id,
          name,
          cpf,
          crm,
          email,
          phone,
          monthly_income,
          state_id,
          birthday,
          schedule_restriction,
          schedule_restriction_reason,
          companies: companiesData,
        } = physicianData

        const companies = companiesData.map(company => ({
          cnpj: company.cnpj.replace(/\D/g, ''),
          name: company.name,
          active: company.active,
          id: company.physician_company_id,
        }))

        const data = {
          companies,
          name,
          schedule_restriction,
          schedule_restriction_reason,
          cpf: cpf.replace(/\D/g, ''),
          crm,
          birthday,
          email,
          phone: phone.replace(/\D/g, ''),
          monthly_income,
          state_id,
        }
        axios
          .put(`/physician/${id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    storePhysician(ctx, physicianData) {
      return new Promise((resolve, reject) => {
        const {
          companies: companiesData,
          ...data
        } = physicianData
        const companies = companiesData.map(company => ({ cnpj: company.cnpj.replace(/\D/g, ''), name: company.name, active: company.active }))

        axios.post('/physician', { ...data, companies })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    fetchStates(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/address/states', params)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    fetchFinancialInstitutions(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/financial-institution/', params)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    fetchCities(ctx, { stateId }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/address/cities?state_id=${stateId}`)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    sendInvite(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/physician/${id}/reinvite`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    generateConfirmationUrl(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/physician/${id}/confirmation-url`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    generateConfirmationUrlFull(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/physician/${id}/complete-confirmation-url`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    searchPhysicianByCpf(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/physician/by-cpf', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
